import React, { FunctionComponent } from 'react'
import Section from './shared/section/sectionWrapper';
import { StaticImage } from 'gatsby-plugin-image'
import Text from './shared/text';
const EngineeringProficiency:FunctionComponent = ()=> {
    return (
        <Section className="bg-white relative  px-6 ">
            <div className="flex flex-col w-full h-full p-5">
                <Text className="text-center text-bytestrone-blue lg:text-left  mb-5" textType="headerL">Our Engineering Proficiency</Text>
                <div className="hidden md:block m-auto"><StaticImage alt="proficiency" className="object-fill" src="../images/our-engineering-proficiency.png" placeholder="blurred" quality={75}/></div>
                <div className="block md:hidden m-auto w-full">
                    <div className="w-full border-blue-900 border-2 border-dashed my-1"></div>
                    <div className=" w-full py-1   text-center rounded-md font-semibold  text-white bg-blue-400">Web & Mobile *</div>
                    <StaticImage alt="proficiency" className="object-fill" src="../images/proficiency-web-and-mobile-web.png" placeholder="blurred" quality={75}/>

                    <div className="w-full border-blue-900 border-2 border-dashed my-1"></div>
                    <div className=" w-full py-1  text-center rounded-md font-semibold  text-white bg-blue-600">Programming Language & Middleware *</div>
                    <StaticImage alt="proficiency" className="object-fill" src="../images/proficiency-languages-and-middleware-web.png" placeholder="blurred" quality={75}/>

                    <div className="w-full border-blue-900 border-2 border-dashed my-1"></div>
                    <div className=" w-full py-1  text-center rounded-md font-semibold  text-white bg-blue-800">Databases *</div>
                    <StaticImage alt="proficiency" className="object-fill" src="../images/proficiency-databases-web.png" placeholder="blurred" quality={75}/>
                    
                    <div className="w-full border-blue-900 border-2 border-dashed my-1"></div>
                    <div className=" w-full py-1  text-center rounded-md font-semibold  text-white bg-purple-700">Cloud *</div>
                    <StaticImage alt="proficiency" className="object-fill" src="../images/proficiency-cloud-web.png" placeholder="blurred" quality={75}/>

                    <div className="w-full border-blue-900 border-2 border-dashed my-1"></div>
                    <div className=" w-full py-1  text-center rounded-md font-semibold  text-white bg-red-800 ">Products & Standards *</div>
                    <StaticImage alt="proficiency" className="object-fill" src="../images/proficiency-products-and-standards-web.png" placeholder="blurred" quality={75}/>

                    <div className="w-full border-blue-900 border-2 border-dashed my-1"></div>
                    <div className=" w-full py-1  text-center rounded-md font-semibold  text-white bg-red-500 ">Tools & Frameworks *</div>
                    <StaticImage alt="proficiency" className="object-fill w-full" src="../images/proficiency-tools-and-frameworks.png" placeholder="blurred" quality={75}/>
                </div>
            </div>
        </Section>
    )
}

export default EngineeringProficiency;