import React, { FunctionComponent } from 'react'
import Section from './shared/section/sectionWrapper';
import { StaticImage } from 'gatsby-plugin-image'
import Text from './shared/text';
const ServiceOfferings:FunctionComponent = ()=> {
    return (
        <Section className="bg-bytestrone-blue relative  px-6 ">
            {/* <StaticImage style={{position:'absolute'}} className="absolute bottom-0 right-0 md:w-1/2" placeholder={'none'} src={"../images/line_green.svg"} alt="background wave"/> */}
            <div className="flex flex-col w-full h-full  p-5 md:py-10 py-20">
                <Text className="text-center text-white lg:text-right whitespace-nowrap mb-5" textType="headerL">Our Service Offerings</Text>
                <div className="hidden md:block w-3/4 m-auto"><StaticImage alt="pod charactristics" src="../images/our-service-offerings.png" placeholder="blurred" quality={75}/></div>
                <div className="md:hidden m-auto"><StaticImage alt="pod charactristics" src="../images/our-service-offerings-mobile.png" placeholder="blurred" quality={75}/></div>
    
            <div className="pt-10">
                <ol className="list-decimal text-white text-xs">
                  <li className="pb-5">
                    Systems Integration - Insurance Policy Systems Integration, HL7 Integration, ACORD Integration.
                  </li>
                  <li className="pb-5">
                  Go-Forward team - Technically superior, high performing, multi-skilled team who can architect, build application framework, implement complex components and guide rest of the development team.
                  </li>
                  <li>Techno SoS - Rapid assessment & recovery of technically challenged projects</li>
                 
                </ol>
            </div>
                
            </div>
        </Section>
    )
}

export default ServiceOfferings;