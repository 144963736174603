import React, { FunctionComponent } from 'react'
import Section from './shared/section/sectionWrapper';
import { StaticImage } from 'gatsby-plugin-image'
import Text from './shared/text';
const EngineeringMetrics:FunctionComponent = ()=> {
    return (
        <Section className="bg-white relative  px-6 ">
            <div className="flex flex-col w-full h-full p-5">
                <Text className="text-center text-bytestrone-blue lg:text-left  mb-5" textType="headerL">Engineering Metrics</Text>
                <div className="w-full flex flex-col justify-center items-center">
                    <span className="text-xs md:text-xl lg:text-3xl xl:text-4xl py-5 md:m-5 lg:mx-10 xl:mx-16">We hold ourselves to highest standards of quality. We measure our Engineering Performance through defined metrics using industry standard tools. We continuously monitor and report our performance.</span>
                    <div className="w-11/12 md:w-3/4 xl:w-1/2 flex flex-col justify-center items-center">
                        <StaticImage alt="proficiency" className="object-fill" src="../images/engineering-metrics.png" placeholder="blurred" quality={75}/>
                    </div>
                </div>
            </div>
        </Section>
    )
}

export default EngineeringMetrics;